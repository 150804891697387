import React from "react"

const PaymentStripeRedirect = ({ location }) => {
  const redirect = `https://pro.simulassur.fr/PaiementStripe${location.search}`
  if (typeof window !== `undefined`) window.location.replace(redirect);
  return (
    <div>
      Cliquez sur le lien si la redirection ne fonctionne pas : <a className="text-secondary" href={redirect}>{redirect}</a>
    </div>
  )
}

export default PaymentStripeRedirect
